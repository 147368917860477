import React from 'react';
import { motion } from 'framer-motion';

const About = () => {
    return (
        <section
            id="about"
            className="w-full pt-24 bg-slate-900 text-gray-300 py-16 lg:py-24"
        >
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                {/* Header */}
                <div className="text-center mb-16">
                    <motion.h2
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        className="text-4xl font-bold inline-block border-b-4 border-purple-600 pb-2"
                    >
                        About Me
                    </motion.h2>
                </div>

                {/* Main Content */}
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-12 lg:gap-16">
                    {/* Left Column */}
                    <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.6, delay: 0.2 }}
                        className="space-y-6"
                    >
                        <div className="bg-slate-800/50 p-6 rounded-lg border border-purple-800/20">
                            <h3 className="text-2xl sm:text-3xl font-bold text-purple-400 mb-4">
                                Hello, I'm Anamol
                            </h3>
                            <p className="text-lg text-gray-300 leading-relaxed">
                                A dedicated full-stack developer with a passion for creating elegant,
                                efficient, and user-centric web applications. With a strong foundation
                                in modern web technologies, I transform complex problems into simple,
                                beautiful solutions.
                            </p>
                        </div>

                        {/* Skills Overview */}
                        <div className="bg-slate-800/50 p-6 rounded-lg border border-purple-800/20">
                            <h4 className="text-xl font-semibold text-purple-400 mb-3">
                                Core Competencies
                            </h4>
                            <ul className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3 text-gray-300">
                                <li className="flex items-center space-x-2">
                                    <span className="w-2 h-2 bg-purple-500 rounded-full"></span>
                                    <span>Frontend Development</span>
                                </li>
                                <li className="flex items-center space-x-2">
                                    <span className="w-2 h-2 bg-purple-500 rounded-full"></span>
                                    <span>Backend Architecture</span>
                                </li>
                                <li className="flex items-center space-x-2">
                                    <span className="w-2 h-2 bg-purple-500 rounded-full"></span>
                                    <span>Database Design</span>
                                </li>
                                <li className="flex items-center space-x-2">
                                    <span className="w-2 h-2 bg-purple-500 rounded-full"></span>
                                    <span>API Development</span>
                                </li>
                            </ul>
                        </div>
                    </motion.div>

                    {/* Right Column */}
                    <motion.div
                        initial={{ opacity: 0, x: 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.6, delay: 0.4 }}
                        className="space-y-6"
                    >
                        <div className="bg-slate-800/50 p-6 rounded-lg border border-purple-800/20">
                            <h4 className="text-xl font-semibold text-purple-400 mb-3">
                                Professional Journey
                            </h4>
                            <p className="text-gray-300 leading-relaxed mb-4">
                                With a strong background in JavaScript and modern web frameworks,
                                I specialize in building scalable web applications that deliver
                                exceptional user experiences. My approach combines technical
                                expertise with creative problem-solving to create innovative
                                solutions.
                            </p>
                            <p className="text-gray-300 leading-relaxed">
                                I'm constantly expanding my knowledge and staying current with
                                industry trends, believing that continuous learning is key to
                                delivering cutting-edge solutions in the ever-evolving tech landscape.
                            </p>
                        </div>

                        {/* Call to Action */}
                        <div className="bg-purple-900/20 p-6 rounded-lg border border-purple-800/20">
                            <h4 className="text-xl font-semibold text-purple-400 mb-3">
                                Let's Connect
                            </h4>
                            <p className="text-gray-300 leading-relaxed">
                                I'm always open to discussing new projects, creative ideas, or
                                opportunities to be part of your visions. Feel free to reach out
                                for collaborations or just a friendly hello.
                            </p>
                        </div>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default About;
