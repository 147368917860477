import React from "react";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { motion } from "framer-motion";

const SocialLinks = () => {
    const links = [
        {
            id: 1,
            child: (
                <>
                    LinkedIn <FaLinkedin className="text-2xl" />
                </>
            ),
            href: "https://www.linkedin.com/in/anamolsapkota",
            style: "rounded-tr-md"
        },
        {
            id: 2,
            child: (
                <>
                    GitHub <FaGithub className="text-2xl" />
                </>
            ),
            href: "https://github.com/anamolsapkota",
        },
        {
            id: 3,
            child: (
                <>
                    Mail <HiOutlineMail className="text-2xl" />
                </>
            ),
            href: "mailto:info@sapkotaanamol.com",
        },
        {
            id: 4,
            child: (
                <>
                    Resume <BsFillPersonLinesFill className="text-2xl" />
                </>
            ),
            href: "/resume.pdf",
            style: "rounded-br-md",
            download: true
        },
    ];

    return (
        <motion.div
            className="hidden lg:flex flex-col top-[35%] left-0 fixed"
            initial={{ x: -100 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
        >
            <ul>
                {links.map(({ id, child, href, style, download }) => (
                    <motion.li
                        key={id}
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 0.5,
                            delay: id * 0.1
                        }}
                        className={`
                            flex justify-between items-center 
                            w-40 h-14 px-4 
                            ml-[-100px] 
                            hover:ml-[-10px] 
                            duration-300 
                            bg-purple-800
                            ${style}
                            relative
                            group
                        `}
                    >
                        <a
                            href={href}
                            className="flex justify-between items-center w-full text-white"
                            download={download}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {child}
                        </a>

                        {/* Subtle hover effect */}
                        <div className="absolute inset-0 bg-purple-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300 -z-10" />
                    </motion.li>
                ))}
            </ul>
        </motion.div>
    );
};

export default SocialLinks;