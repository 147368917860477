import React from 'react'
import anamol from '../assets/images/anamol.jpg'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { AiFillInstagram, AiFillLinkedin, AiFillGithub } from 'react-icons/ai';
import { TypeAnimation } from 'react-type-animation';
import { Link } from "react-scroll";

const Home = () => {
    return (
        <div name="home" id="home" className='pt-16 min-h-screen md:h-screen w-full bg-[#0a192f] relative'>

            <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-8 gap-10 md:flex-row-reverse'>

                {/* Image */}
                <div className='pt-16 sm:pt-0'>
                    <img src={anamol} alt='Anamol' className='rounded-full w-36 h-36 md:w-80 md:h-80 border-4 border-purple-500 shadow-lg transform transition-all duration-300 hover:scale-105' />
                </div>

                {/* Text Section */}
                <div className='flex flex-col justify-center h-full'>
                    <h2 className='text-4xl md:text-5xl py-2 font-semibold text-gray-300'>
                        Hi, I'm <span className='text-purple-400'>Anamol Sapkota</span>
                    </h2>

                    {/* Typing Animation */}
                    <h3 className="text-md md:text-xl py-2 text-gray-300 font-light">
                        <TypeAnimation
                            sequence={[
                                'System/Network Administrator',
                                1000,
                                'Tech Enthusiast',
                                1000,
                                'Web Developer',
                                1000,
                                'Network/Cyber Security Enthusiast',
                                1000,
                            ]}
                            wrapper="span"
                            speed={50}
                            style={{ display: 'inline-block' }}
                            repeat={Infinity}
                        />
                    </h3>

                    <p className="text-justify text-md py-5 leading-8 max-w-xl mx-auto md:text-lg text-gray-300">
                        Anamol Sapkota (Nepali: अनमोल सापकोटा) is a Web Developer, Tech Enthusiast, and Network/Cyber Security Enthusiast based in Central Nepal. He has expertise in network and cybersecurity management and is passionate about technology innovation, focusing on integrating marketing strategies with technological advancements.</p>

                    <div className='flex justify-between'>
                        {/* Portfolio Link */}
                        <Link to="portfolio" smooth duration={500} className="group text-lg bg-gradient-to-r from-purple-600 to-purple-950 text-white px-6 py-3 rounded-md hover:bg-gradient-to-r hover:from-purple-800 hover:to-purple-600 my-6 flex items-center cursor-pointer transform transition-all duration-300 hover:scale-105">
                            Portfolio
                            <span className='group-hover:rotate-90 duration-300'>
                                <MdOutlineKeyboardArrowRight className="ml-2" />
                            </span>
                        </Link>

                        {/* Social Icons */}
                        <div className="text-5xl flex gap-5 py-2 text-gray-300">
                            <a href="https://github.com/anamolsapkota" target="_blank" rel="noopener noreferrer">
                                <AiFillGithub className="text-3xl hover:text-purple-500 transform transition-all duration-300 hover:scale-110" />
                            </a>
                            <a href="https://np.linkedin.com/in/anamolsapkota" target="_blank" rel="noopener noreferrer">
                                <AiFillLinkedin className="text-3xl hover:text-blue-600 transform transition-all duration-300 hover:scale-110" />
                            </a>
                            <a href="https://instagram.com/anamolsapkota" target="_blank" rel="noopener noreferrer">
                                <AiFillInstagram className="text-3xl hover:text-pink-600 transform transition-all duration-300 hover:scale-110" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;
