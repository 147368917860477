import React from 'react';
import html from "../assets/experience/html.png";
import css from "../assets/experience/css.png";
import javascript from "../assets/experience/javascript.png";
import node from "../assets/experience/node.png";
import reactimage from "../assets/experience/react.png";
import nextjs from "../assets/experience/nextjs.png";
import github from "../assets/experience/github.png";
import tailwind from "../assets/experience/tailwind.png";

const Experience = () => {
    const experience = [
        { id: 1, src: html, title: 'HTML', style: 'shadow-orange-500' },
        { id: 2, src: css, title: 'CSS', style: 'shadow-blue-500' },
        { id: 3, src: javascript, title: 'JavaScript', style: 'shadow-yellow-500' },
        { id: 4, src: node, title: 'Node.Js', style: 'shadow-green-500' },
        { id: 5, src: reactimage, title: 'React.Js', style: 'shadow-blue-600' },
        { id: 6, src: nextjs, title: 'Next.Js', style: 'shadow-white' },
        { id: 7, src: github, title: 'GitHub', style: 'shadow-gray-400' },
        { id: 8, src: tailwind, title: 'Tailwind CSS', style: 'shadow-sky-400' }
    ];

    return (
        <div name="experience" id="experience" className="w-full min-h-screen pt-20 bg-[#0a192f] text-gray-300">
            <div className='max-w-screen-lg mx-auto p-8 flex flex-col justify-center w-full h-full'>
                <div className='text-center mb-8'>
                    <p className='text-4xl font-bold inline border-b-4 border-purple-800 text-white'>
                        Experience
                    </p>
                    <p className='py-4 text-lg text-gray-400'>
                        Here are the technologies I've worked with and mastered over time.
                    </p>
                </div>

                <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-8 text-center'>
                    {experience.map(({ id, src, title, style }) => (
                        <div
                            key={id}
                            className={`shadow-lg hover:scale-105 transform transition duration-300 ease-in-out py-4 rounded-lg ${style} flex flex-col items-center`}
                        >
                            <img src={src} alt={title} className="w-20 h-20 mb-4 mx-auto" />
                            <p className="text-xl font-semibold">{title}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Experience;
